.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
  }
  
  .login-box {
    width: 350px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .login-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .login-logo img {
    width: 60%;
  }
  
  .login-form .ant-form-item {
    margin-bottom: 20px;
  }
  
  .login-btn-item {
    display: flex;
    justify-content: center;
  }
  
  .login-btn {
    width: 100%;
    background-color: #564dfc; /* Adjust to your primary color */
    border: none;
  }
  
  .forgot-password {
    display: block;
    text-align: center;
    margin-top: 10px;
  }
  
  .signup-link {
    text-align: center;
    margin-top: 20px;
  }
  
  .remember-me {
    display: flex;
    justify-content: center;
  }
  