.my-leads-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }
.upload-section {
    width: 90%;
    max-width: 1125px;
    height: 131px;
    padding: 24px 36px;
    gap: 0;
    border-radius: 16px 0 0 0;
    border: 1px solid #d9d9d9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 1;
    background-color: #fff;
    margin-bottom: 15px;
    transition: all 0.3s ease;
  }
  
  .upload-content {
    display: flex;
    align-items: center;
  }
  
  .upload-icon {
    width: 64px; /* Adjust as needed */
    height: 64px; /* Adjust as needed */
    margin-right: 16px; /* Space between icon and text */
  }
  
  .upload-text h3 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .upload-text p {
    margin: 0;
    font-size: 14px;
    color: #666;
  }
  
  .upload-button {
    height: 40px;
    display: flex;
    align-items: center;
  }
  
  .gen-content-section {
    width: 90%;
    max-width: 1125px;
    transition: all 0.3s ease;
  }
  
  .left-section {
    width: 100%;
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 1200px) {
    .upload-section, .content-section {
      width: 90%;
    }
  
    .upload-icon {
      width: 48px;
      height: 48px;
      margin-right: 12px;
    }
  
    .upload-text h3 {
      font-size: 14px;
    }
  
    .upload-text p {
      font-size: 12px;
    }
  
    .upload-button {
      height: 36px;
    }
  }
  
  @media (max-width: 768px) {
    .upload-section, .content-section {
      width: 100%;
      flex-direction: column;
      height: auto;
      padding: 16px 24px;
    }
  
    .upload-icon {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }
  
    .upload-text h3 {
      font-size: 12px;
    }
  
    .upload-text p {
      font-size: 10px;
    }
  
    .upload-button {
      width: 100%;
      justify-content: center;
      margin-top: 12px;
    }
  }
  
  /* Add this CSS to your stylesheet */
.large-title {
  font-size: 24px; /* Adjust the size as needed */
  font-weight: bold; /* Optional: make the font bold */
}

.copy-button {
  display: block;
  margin-left: auto;
  margin-right: 0;
}

.statistics-section {
  width: 90%;
  max-width: 1125px;
  height: auto;
  padding: 24px 36px;
  border-radius: 0 16px 0 0;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 1;
  margin-left: 15px;
}

.leads-and-stats {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Aligns the top of both sections */
  width: 100%;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .leads-and-stats {
      flex-direction: column;
      align-items: center;
  }

  .statistics-section {
      margin-left: 0;
      margin-top: 15px;
  }
}
