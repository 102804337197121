.clickable-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
  }
  
  .message-box {
    border-radius: 10px;
  }
  
  .user-message {
    align-self: flex-start;
  }
  
  .contact-message {
    align-self: flex-end;
  }
  
  .message-sender {
    font-weight: bold;
  }
  
  .message-body {
    margin-top: 5px;
  }
  
  .message-date {
    font-size: 12px;
    color: gray;
    margin-top: 5px;
  }
  
/* Inbox.css */
.ant-layout {
  display: flex;
  height: 100vh;
}

.ant-layout-sider {
  height: 100%;
}

.ant-layout-content {
  height: 100%;
  overflow-y: auto;
}
